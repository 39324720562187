const code = `
  name: Run Bastila Post Merge Check on Successful Merges into Main

  on:
    push:
      branches:
        - main

  jobs:
    bastila_post_merge_check:
      runs-on: ubuntu-latest
      steps:
        - name: Checkout repository
          uses: actions/checkout@v2

        - name: Run Bastila Post Merge Check
          uses: ProgrammingJoe/bastila-action@v0.4.0
          with:
            BASTILA_KEY: \${{ secrets.BASTILA_KEY }}
            POST_RESULTS: 'true'
            PREVENT_REGRESSION: 'true'
`

export default code
