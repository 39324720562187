import { MarketingH1, MarketingH2, MarketingH3, MarketingP, Paragraph, ModalH3, MarketingSubtext } from './helpers/Typography';
import styled from 'styled-components'
import Login from './Login'
import { FlexVertCenter, FlexRowGap } from './helpers/FlexContainers';

const Content = styled.div`
  max-width: 1200px;
  margin: auto;
  text-align: center;
  padding: 0px 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 1;
  position: relative;

  h1 {
    padding: 0 20px;
    margin: auto;
    margin-top: 0px;
    margin-bottom: 12px;
  }

  h2 {
    margin-bottom: 60px;
  }

  img.logo {
    max-width: 200px;
    margin-top: 60px;
    margin-bottom: 80px;
  }

  img.app-image {
    max-width: 1050px;
    width: 100%;
    box-shadow: 0px -32.79034423828125px 163.9517364501953px 0px #000000B2;

    @media (max-width: 1200px) {
      width: 100%;
    }
  }

  button {
    z-index: 10;
  }

  @media (max-width: 860px) {
    img.logo {
      max-width: 120px;
      margin-top: 20px;
      margin-bottom: 40px;
    }
    
    img.app-image {
      display: none;
    }
  }
`

const Wrapper = styled.div`
  width: 100vw;
  position: relative;
  overflow: hidden;

  .gradient-1 {
    background: radial-gradient(42.37% 50.07% at 50% 49.93%, rgba(255, 90, 40, 0.4) 0%, rgba(255, 90, 40, 0) 100%);
    width: 200vh;
    height: 100vh;
    position: absolute;
    top: -500px;
    right: -900px;
  }

  .gradient-2 {
    background: radial-gradient(42.37% 50.07% at 50% 49.93%, rgba(255, 90, 40, 0.4) 0%, rgba(255, 90, 40, 0) 100%);
    width: 120vh;
    height: 120vh;
    position: absolute;
    top: 10vh;
    left: -40vh;
  }

  .gradient-3 {
    background: radial-gradient(42.37% 50.07% at 50% 49.93%, rgba(185, 214, 242, 0.4) 0%, rgba(185, 214, 242, 0) 100%);
    width: 100vh;
    height: 100vh;
    position: absolute;
    top: 40vh;
    right: -40vh;
  }

  .gradient-4 {
    background: linear-gradient(180deg, rgba(185, 214, 242, 0), rgba(185, 214, 242, 0.2), rgba(255, 59, 0, 0.7));
    width: 200vh;
    height: 160vh;
    position: absolute;
    bottom: 0vh;
    right: 0px;
  }

  @media (max-width: 860px) {
    .gradient-1 {
      width: 120vh;
      height: 60vh;
    }

    .gradient-2 {
      width: 80vh;
      height: 80vh;
    }

    .gradient-3 {
      width: 50vh;
      height: 50vh;
    }
  }
`

const Section = styled(FlexVertCenter)`
  margin: 9rem 0.5rem;
  
  h1 {
    max-width: 1100px;
    margin-bottom: 1.5rem;
  }

  h2 {
    max-width: 900px;
    margin-bottom: 4rem;
  }

  @media (max-width: 860px) {
    margin: 5.5rem 0.5rem;
  }
`

const Tile = styled.div`
  padding: 2.5rem;
  background-color: #101010;
  width: ${({ full }) => full ? '100%' : '50%'};
  margin: 0 auto 1rem auto;
  text-align: left;
  display: flex;
  flex-direction: column;
  border-radius: 0.75rem;

  h3 {
    margin-bottom: 0.5rem;
  }

  p {
    margin-bottom: 1rem;
    flex-grow: 1;
  }

  img {
    width: 100%;
    margin-bottom: ${({ full }) => full ? '0rem' : '-2.5rem'};
  }

  @media (max-width: 860px) {
    width: 100%;
  }
`

const TileRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 1rem;

  @media (max-width: 860px) {
    flex-wrap: wrap;
    gap: 0rem;
  }
`

const SubRow = styled(FlexRowGap)`
  width: 100%;
  gap: 1rem;
  flex-wrap: wrap;
`

const SubSlot = styled.div`
  border-radius: 0.75rem;
  background: #101010;
  padding: 2.5rem;
  text-align: left;
  flex: 1;
  min-width: 180px;

  h3 {
    margin-bottom: 0.5rem;
  }

  p:last-of-type {
    margin-top: 4.5rem;
  }

  > div {
    flex-grow: 1;
  }
`

const TestimonialRow = styled(FlexRowGap)`
  width: 101vw;
  gap: 1rem;
  align-items: start;
  justify-content: center;
  flex-wrap: wrap;
`

const Testimonial = styled.div`
  border-radius: 0.75rem;
  background: #101010;
  padding: 2.5rem;
  flex: 1;
  max-width: 300px;
  text-align: left;
  min-width: 200px;

  p:first-of-type {
    margin-bottom: 1rem;
  }
`

export default function CoverPage({ showApp }) {

  return (
    <Wrapper>
      <div className="gradient-1"/>
      <div className="gradient-2"/>
      <div className="gradient-3"/>
      <div className="gradient-4"/>
      <Content>
        <Section>
          <img src={require('../assets/logo-orange.png')} alt="Bastila Logo" className="logo" />
          <MarketingH1>Remove deprecated code</MarketingH1>
          <MarketingH2>Refine and establish your development standards</MarketingH2>
          <Login/>
        </Section>
        <img src={require('../assets/desktop.png')} alt="Bastila Logo" className="app-image" />

        <Section>
          <MarketingH1>A breezy setup</MarketingH1>
          <MarketingH2>Bastila searches files for strings, we don't care what the file types are</MarketingH2>

          <TileRow>
            <Tile>
              <MarketingH3>Define</MarketingH3>
              <MarketingP>Write regex patterns or simple strings that represent the code you want removed from your code base.</MarketingP>
              <img src={require('../assets/Modal.png')} alt="Code Standard Form" />
            </Tile>

            <Tile>
              <MarketingH3>Prevent</MarketingH3>
              <MarketingP>Setup a pull request workflow to search your code for the predefined strings.</MarketingP>
              <img src={require('../assets/Terminal.png')} alt="Code Standard Progress" />
            </Tile>
          </TileRow>

          <Tile full={true}>
            <MarketingH3>Track</MarketingH3>
            <MarketingP>Setup a pull request workflow to save the progress your team has made in removing old patterns.</MarketingP>
            <img src={require('../assets/Slot.png')} alt="Code Standard Progress" />
          </Tile>
        </Section>

        <Section>
          <MarketingH1>No subscription B.S.</MarketingH1>
          <MarketingH2>Start free and only pay for the slots you use with bulk purchase discounts for when 
            you need more. You pay for your slots once, regardless of their use</MarketingH2>

          <SubRow>
            <SubSlot>
              <ModalH3>10 Slots</ModalH3>
              <MarketingSubtext>$4 per slot</MarketingSubtext>
              <Paragraph>$40</Paragraph>
            </SubSlot>

            <SubSlot>
              <ModalH3>30 Slots</ModalH3>
              <MarketingSubtext>$3.30 per slot</MarketingSubtext>
              <Paragraph>$100</Paragraph>
            </SubSlot>

            <SubSlot>
              <ModalH3>50 Slots</ModalH3>
              <MarketingSubtext>$3 per slot</MarketingSubtext>
              <Paragraph>$150</Paragraph>
            </SubSlot>
          </SubRow>
        </Section>

        <Section>
          <MarketingH1>Still not convinced? See why some of our friends are</MarketingH1>

          <TestimonialRow>
            <Testimonial>
              <Paragraph>I've needed a tool like Bastila my whole life</Paragraph>
              <MarketingSubtext>Monté, junior developer</MarketingSubtext>
            </Testimonial>

            <Testimonial>
              <Paragraph>I would have loved to have Bastila when I was working as a junior dev</Paragraph>
              <MarketingSubtext>Sarah, junior developer</MarketingSubtext>
            </Testimonial>
          </TestimonialRow>
        </Section>
      </Content>
    </Wrapper>
  );
}