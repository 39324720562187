import styled from "styled-components";

export const ContentContainer = styled.div`
  max-width: 800px;
  width: 100%;
  margin: 2rem auto 80px auto;
  padding: 40px 20px 80px 20px;
  z-index: 2;
`

export const Form = styled.form`
  padding: 20px;
  max-width: ${props => props.maxWidth};
  margin: auto;
`

export const FormField = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 6px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`

export const Card = styled.div`
  padding: 16px;
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};
  text-align: left;
  border: ${({ theme }) => `1px solid ${theme.colors.grey400}`};
`

export const Divider = styled.div`
  height: 1px;
  width: 90%;
  background-color: ${(props) => props.theme.colors.grey300};
  margin: 0.5rem auto;
`

export const Pill = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 0.75rem;
  border-radius: 100px;
  background: rgba(255, 59, 0, 0.30);

  svg {
    width: 0.7rem;
    height:  0.7rem;
  }

  p {
    font-size: 0.9rem;
  }
`