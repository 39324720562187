import Modal from 'react-modal';
import styled from "styled-components"
import { useState } from 'react';
import { X, ArrowDown } from 'react-feather';
import { PrimaryBtn } from 'components/helpers/Buttons';
import { Subtext, ModalH3, FormError } from "../helpers/Typography"
import APIClient from "../../api-client"
import TextInput from "../TextInput"
import { ModalFooter, ModalHeader } from './helpers';

const Form = styled.form`
  display: flex;
  flex-direction: column;

  svg {
    color: ${({ theme }) => theme.colors.secondary};
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }
`

export default function AddStandardModal({ showModal, closeModal, currentRepo, addRecord, standards }) {
  const [oldstandard, setOldStandard] = useState('')
  const [includePaths, setIncludePaths] = useState('')
  const [excludePaths, setExcludePaths] = useState('')
  const [instructions, setInstructions] = useState('')
  const [error, setError] = useState('')

  const submitNewStandard = async () => {
    setError('')

    if (oldstandard === '' || instructions === '') {
      setError('The deprecated and replacement fields are required')
      return
    }

    if (standards.find((s) => s.snippet === oldstandard)) {
      setError('You are already tracking this standard')
      return
    }

    try {
      new RegExp(oldstandard)
    } catch(e) {
      setError('The deprecated string must be valid regex')
      return
    }

    try {
      const standard = {
        snippet: oldstandard,
        fix_recommendation: instructions,
        repo: currentRepo.id
      }
      if (includePaths) {
        standard['include_paths'] = includePaths.split(',')
      }
      if (excludePaths) {
        standard['exclude_paths'] = excludePaths.split(',')
      }
    
      const response = await APIClient.post('standard-changes/', standard)

      addRecord(response.data)
    } catch {

    }
  }

  return (
    <Modal
      isOpen={showModal}
      onRequestClose={closeModal}
      style={{
        overlay: {
          backgroundColor: 'rgba(24, 24, 24, 0.8)'
        },
        content: {
          backgroundColor: '#000000',
          boxShadow: '0px 40px 40px 0px #00000099',
          width: '100%',
          maxWidth: '600px',
          margin: 'auto',
          height: 'fit-content',
          borderRadius: '4px',
          border: error ? '1px solid #FF3232' : ''
        }
      }}
    >
      <ModalHeader>
        <ModalH3>New Standard</ModalH3>
        <X onClick={() => closeModal()} />
      </ModalHeader>

      <Form>
        <TextInput
          label="Deprecated Code"
          name="deprecatedcode"
          type="text"
          placeholder="<DeprecatedComponent>"
          value={oldstandard}
          change={(e) => setOldStandard(e)}
        />
        <TextInput
          label="Include Paths"
          name="includePaths"
          type="text"
          placeholder="e.g. *.ts, src/**/include"
          value={includePaths}
          change={(e) => setIncludePaths(e)}
        />
        <TextInput
          label="Exclude Paths"
          name="excludePaths"
          type="text"
          placeholder="e.g. *.py, **/tests/"
          value={excludePaths}
          change={(e) => setExcludePaths(e)}
        />
        <ArrowDown/>
        <TextInput
          label="Replacement Instructions"
          name="instructions"
          type="text"
          placeholder="Use <NewComponent/> or <NewComponent2/>"
          value={instructions}
          change={(e) => setInstructions(e)}
        />
      </Form>

      <ModalFooter>
        {error ? (
          <FormError>{ error }</FormError>
        ) : (
          <Subtext>Standards cannot be edited after they receive data.</Subtext>
        )}
        <PrimaryBtn small={true} onClick={() => submitNewStandard()}>Save</PrimaryBtn>
      </ModalFooter>
    </Modal>
  )
}