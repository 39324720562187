import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import APIClient from './../api-client'


const UserProvider = ({ children }) => {
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [apiReady, setAPIReady] = useState(false)

  useEffect(() => {
    setAPIReady(false)

    const getUserMetadata = async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          audience: `https://bastilaapp.us.auth0.com/api/v2/`,
          scope: "email",
        });

        APIClient.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
        setAPIReady(true)
      } catch (e) {
        console.log(e.message);
      }
    };

    if (isAuthenticated) {
      getUserMetadata()
    } else {
      setAPIReady(true)
    }
  }, [isAuthenticated, isLoading, getAccessTokenSilently]);

  return (
    <div>
      {apiReady ? (<div>{children}</div>) : (<div/>)}
    </div>
  );
};

export default UserProvider;
