import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from 'styled-components';
import { Auth0Provider } from "@auth0/auth0-react";
import UserProvider from 'providers/user-provider';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={{
      colors: {
        primary: '#181818',
        secondary: '#FF3B00',
        darkOrange: '#74260E',
        lightOrange: '#FF6C41',
        black: '#000000',
        grey500: '#232323',
        grey400: '#4E4E4E',
        grey300: '#A3A3A3',
        white: '#FFFFFF',
        errorRed: '#FF3232'
      }
    }}>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH_DOMAIN}
        clientId={process.env.REACT_APP_AUTH_CLIENT_ID}
        useRefreshTokens={true}
        cacheLocation="localstorage"
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: "https://bastilaapp.us.auth0.com/api/v2/",
          scope: "email"
        }}
      >
        <UserProvider>
          <App />
        </UserProvider>
      </Auth0Provider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
