import { FlexRowGap } from './helpers/FlexContainers';
import styled from 'styled-components';
import { useAuth0 } from "@auth0/auth0-react";
import { PPTextButton } from './helpers/Buttons';
import { Paragraph } from './helpers/Typography';
import { useEffect, useState } from 'react';
import CustomDropdown from './Dropdown';
import { Pill } from './helpers/Containers';
import { AlertCircle } from 'react-feather';

const Container = styled.div`
  position: fixed;
  top: 0;
  width: 100vw;
  padding: 16px;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.grey400}`};
  background-color: ${({ theme, scrolled }) => scrolled ? 'rgba(24, 24, 24, 0.2)' : theme.colors.primary};
  backdrop-filter: blur(4px);
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    width: 360px;
  }

  img {
    height: 20px;
    &:hover {
      cursor: pointer;
    }
  }
`

const Error = styled(Pill)`
  top: 64px;
  margin: auto;
  position: relative;
  width: fit-content;

  svg {
    width: 1rem;
    height: 1rem;
    color: white;
  }
`

export default function Header({
  showingDocs,
  setDocs,
  repos,
  currentRepo,
  selectRepo,
  openCreateRepo,
  errorMessage
}) {
  const { logout, isAuthenticated } = useAuth0();
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true)
      } else {
        setScrolled(false)
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const logoutUser = async () => {
    logout({ logoutParams: { returnTo: window.location.origin } })
  }

  return (
    <div>
      <Container scrolled={scrolled}>
        { currentRepo && <CustomDropdown repos={repos} currentRepo={currentRepo} selectRepo={selectRepo} openNewRepo={() => openCreateRepo()}/>}
        <img src={require('../assets/logo-orange.png')} alt="Bastila Logo" onClick={() => setDocs(false)} />
        <FlexRowGap gap="24px" style={{ justifyContent: 'flex-end' }}>
          <PPTextButton onClick={() => setDocs(!showingDocs)}>{ showingDocs ? 'App' : 'Docs' }</PPTextButton>
          {isAuthenticated && (
            <FlexRowGap gap="4px">
              <PPTextButton onClick={() => logoutUser()}>Logout</PPTextButton>
            </FlexRowGap>
          )}
        </FlexRowGap>
      </Container>
      {errorMessage && (<Error>
        <AlertCircle/>
        <Paragraph>{ errorMessage }</Paragraph>
      </Error>)}
    </div>
  );
}