import Modal from 'react-modal';
import styled from "styled-components"
import { useState } from 'react';
import { X } from 'react-feather';
import { PrimaryBtn } from 'components/helpers/Buttons';
import { Subtext, ModalH3 } from "../helpers/Typography"
import APIClient from "../../api-client"
import TextInput from "../TextInput"
import { ModalFooter, ModalHeader } from './helpers';

const Form = styled.form`
  display: flex;
  flex-direction: column;

  svg {
    color: ${({ theme }) => theme.colors.secondary};
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }
`

export default function AddStandardModal({ showModal, closeModal, addRecord }) {
  const [name, setName] = useState('')

  const submitRepo = async () => {
    try {
      const response = await APIClient.post('code-repos/', {
        common_name: name
      })

      addRecord(response.data)
    } catch {

    }
  }

  return (
    <Modal
      isOpen={showModal}
      onRequestClose={closeModal}
      style={{
        overlay: {
          backgroundColor: 'rgba(24, 24, 24, 0.8)'
        },
        content: {
          backgroundColor: '#000000',
          boxShadow: '0px 40px 40px 0px #00000099',
          width: '100%',
          maxWidth: '600px',
          margin: 'auto',
          height: 'fit-content',
          border: 'none',
          borderRadius: '4px'
        }
      }}
    >
      <ModalHeader>
        <ModalH3>New Repo</ModalH3>
        <X onClick={() => closeModal()} />
      </ModalHeader>

      <Form>
        <TextInput
          label="Name"
          name="name"
          type="text"
          placeholder="ProductWeb"
          value={name}
          change={(e) => setName(e)}
        />
      </Form>

      <ModalFooter>
        <Subtext>There is no limit to how many repos you can have.</Subtext>
        <PrimaryBtn small={true} onClick={() => submitRepo()}>Save</PrimaryBtn>
      </ModalFooter>
    </Modal>
  )
}