import styled from "styled-components";

export const MarketingH1 = styled.h1`
  font-size: 6rem;
  font-family: PPNeue;
  font-weight: 500;
  line-height: 1;

  @media (max-width: 780px) {
    font-size: 3.5rem;
  }
`

export const MarketingH2 = styled.h2`
  font-size: 1.3rem;
  font-family: NeueMontreal;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.grey300};
`

export const MarketingH3 = styled.h3`
  font-size: 2rem;
  font-family: PPNeue;
  font-weight: 500;
`

export const MarketingP = styled.p`
  font-size: 1.1rem;
  font-family: NeueMontreal;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.grey300};
`

export const MarketingSubtext = styled.p`
  color: ${({ theme }) => theme.colors.grey300};
  font-size: 1rem;
  font-weight: 500;
`

export const DocsH1 = styled.h1`
  font-size: 5rem;
  font-family: PPNeue;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 36px;
`

export const DocsH2 = styled.h2`
  font-size: 2rem;
  font-family: NeueMontreal;
  font-weight: 500;
  line-height: 0.8;
  margin-bottom: 24px;
`

export const DocsH3 = styled.h3`
  font-size: 1.6rem;
  font-family: NeueMontreal;
  font-weight: 500;
  line-height: 0.8;
  margin-bottom: 24px;
`

export const DocsP = styled.p`
  font-size: 1.2rem;
  font-family: NeueMontreal;
  font-weight: 500;
  margin-bottom: 24px;
  line-height: 1.4;

  span {
    font-weight: 400;
  }
`

export const DocsSub = styled.p`
  font-size: 1.05rem;
  font-family: NeueMontreal;
  font-weight: 500;
  margin-bottom: 18px;
  padding: 0 16px;
  line-height: 1.3;
`

export const H1 = styled.h1`
  font-size: 3rem;
  font-family: NeueMontreal;
  font-weight: 600;
`

export const H2 = styled.h2`
  font-size: 1.4rem;
  font-family: NeueMontreal;
  font-weight: 500;
`

export const H3 = styled.h3`
  font-size: 1.3rem;
  font-family: NeueMontreal;
  font-weight: 500;
`

export const ModalH3 = styled.h3`
  font-size: 2rem;
  font-family: PPNeue;
  font-weight: 500;
`

export const H4 = styled.h4`
  font-size: 1.5rem;
  font-family: PPNeue;
`

export const Paragraph = styled.p`
  font-size: 1.2rem;
  font-weight: 500;
  text-align: left;
`

export const Subtext = styled.p`
  color: ${({ theme }) => theme.colors.grey300};
  font-size: 0.8rem;
  font-weight: 500;
`

export const FormError = styled(Subtext)`
  color: ${({ theme }) => theme.colors.errorRed};
`

export const Code = styled.p`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.grey300};
  font-family: NeueMontreal;
  font-weight: 500;
`

export const Instructions = styled.p`
  font-size: 1.4rem;
  font-weight: 500;
`
