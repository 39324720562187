import { FormField } from "./helpers/Containers"

export default function TextInput({
  label, name, placeholder, type, value, change
}) {
  return (
    <FormField>
      <label htmlFor={name}>{label}</label>
      <input
        name={name}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={(e) => change(e.target.value)}
      />
    </FormField>
  )
}