import styled from 'styled-components'

const Loading = styled.div`
  margin: auto;
  width: 140px;
  height: 140px;
  top: calc(50vh - 70px);
  position: relative;

  img {
    width: 100%;
  }
`

export default function LoadingIcon() {
  return (
    <Loading>
      <img src={require('../assets/loop.gif')} alt="loading..." />
    </Loading>
  )
}
