import { Paragraph, DocsP } from "../helpers/Typography";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { ClipboardIcon } from '@heroicons/react/24/outline'
import code from '../helpers/action-code.js';
import { useState } from "react";
import { FlexRowSpace } from "../helpers/FlexContainers";
import { TextButton } from "../helpers/Buttons";

export default function DocsOverview() {
  const [showIsCopied, setShowIsCopied] = useState(false)

  const copySecret = () => {
    navigator.clipboard.writeText(code)
    setShowIsCopied(true)
    setTimeout(() => {
      setShowIsCopied(false)
    }, 2000)
  }

  return (
    <div>
      <DocsP>
        If we don't currently support your code integration tooling with our own package, you can use the script below to implement it yourself.
      </DocsP>
      <FlexRowSpace style={{ marginBottom: '12px' }}>
        <div/>
        {showIsCopied ? <Paragraph>Code copied to clipboard</Paragraph> : (
          <TextButton onClick={() => copySecret()}>
            <ClipboardIcon/>
            Copy code to clipboard
          </TextButton>
        )}
      </FlexRowSpace>
      <SyntaxHighlighter language="python" style={darcula} customStyle={{
        maxHeight: 800,
        backgroundColor: '#000000',
      }}>
        { code }
      </SyntaxHighlighter>
    </div>
  )
}