import styled from 'styled-components';
import Dot from '../svgs/Circle'

const Positioning = styled.div`
  position: fixed;
  left: 2rem;
  bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  z-index: 2;
`

const Pill = styled.div`
  position: fixed;
  left: 2rem;
  bottom: 2rem;

  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 0.75rem;
  border-radius: 100px;
  background: rgba(255, 59, 0, 0.30);

  svg {
    width: 0.7rem;
    height:  0.7rem;
  }

  p {
    font-size: 0.9rem;
  }
`

export default function Info({ slotCount }) {
  return (
    <Positioning>
      <Pill>
        <Dot/>
        <p>{slotCount} Slots Available</p>
      </Pill>
    </Positioning>

  );
}