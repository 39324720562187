import { useState } from 'react';
import { FlexRowSpace, FlexRowGap } from './helpers/FlexContainers';
import styled from 'styled-components';
import RollAPIKey from './RollAPIKey';
import RollSecretModal from './modals/RollSecretModal'
import { TextButton } from './helpers/Buttons';
import { Key } from 'react-feather';

const Bar = styled(FlexRowSpace)`
  padding: 0.5rem;
  margin-bottom: 0.5rem;
`

export default function ListHeader({ currentRepo }) {
  const [showModal, setShowModal] = useState(false)

  return (
    <Bar>
      <div/>
      <FlexRowGap gap="1rem">
        <TextButton onClick={() => setShowModal(true)}><Key/></TextButton>
      </FlexRowGap>
      <RollSecretModal
        showModal={showModal}
        closeModal={() => setShowModal(false)}
        currentRepo={currentRepo}
      />
    </Bar>
  );
}