import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      fill="none"
      viewBox="0 0 10 10"
    >
      <circle cx="5" cy="5" r="5" fill="#FF3B00"></circle>
    </svg>
  );
}

export default Icon;
