import React, { useState } from 'react';
import { PPTextButton } from './helpers/Buttons';
import styled from 'styled-components';
import { Divider } from './helpers/Containers';
import { Plus, ChevronDown } from 'react-feather';
import Circle from '../svgs/Circle'

const Positioning = styled.div`
  position: relative;
`

const Panel = styled.div`
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.black};
  padding: 0.5rem;
  position: absolute;
  top: 30px;
  display: flex;
  flex-direction: column;
`

const RepoBtn = styled.button`
  position: relative;
  padding: 0.5rem;
  background-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.grey300};
  outline: none;
  border: none;
  font-size: 1rem;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 0.25rem;

  &:hover {
    color: ${props => props.theme.colors.white};
    background-color: ${({ theme }) => theme.colors.grey500};
  }
`

const ActiveDot = styled.div`
  position: absolute;
  top: -2px;
  left: 2px;

  svg {
    width: 0.25rem;
    height: 0.25rem;
    color: ${props => props.theme.colors.white};
  }
`

const CustomDropdown = ({ repos, selectRepo, currentRepo, openNewRepo }) => {
    const [showPanel, setShowPanel] = useState(false);

    return (
      <Positioning>
        <PPTextButton onClick={() => setShowPanel(!showPanel)}>
          { currentRepo.commonName }
          <ChevronDown/>
        </PPTextButton>
        {showPanel && <Panel>
          {repos.map((repo) => (<RepoBtn key={repo.id} onClick={() => selectRepo(repo)}>
            { repo.commonName }
            { repo.commonName === currentRepo.commonName && <ActiveDot><Circle/></ActiveDot>}
          </RepoBtn>))}
          <Divider/>
          <RepoBtn onClick={() => openNewRepo()}>
            <Plus/>
            New Repo
          </RepoBtn>
        </Panel>}
      </Positioning>
    );
};

export default CustomDropdown;
