import { DocsP, DocsSub } from "../helpers/Typography";
import styled from "styled-components";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/hljs';

const Instructions = styled(DocsSub)`
  span {
    display: block;
  }
`

export default function GitHookOverview() {
  const gitHook = `
    #!/bin/sh
    bastila_run
  `

  return (
    <div>
      <DocsP>
        We have a pypi package that you can install to easily setup a git pre-commit hook.
      </DocsP>
      <Instructions>
        <span>1. Install the package 'pip install bastila-search'</span>
        <span>2. Create a precommit file 'touch .git/hooks/pre-commit'</span>
        <span>3. Add execute access to that file 'chmod +x .git/hooks/pre-commit'</span>
        <span>4. Add the script to your pre-commit file</span>
      </Instructions>
      <SyntaxHighlighter language="json" style={darcula} customStyle={{
        maxHeight: 500,
        backgroundColor: '#000000',
        marginBottom: '18px'
      }}>
        {gitHook}
      </SyntaxHighlighter>
      <Instructions>
        <span>5. Run the setup command to create a config file with your env vars 'bastila_setup'. This 
        will create a config.json file that should be kept in the root of your repository.</span>
        <span>6. Run a commit to test your pre-commit hook</span>
      </Instructions>
    </div>
  )
}