export function getRelativeDateString(pastDate) {
  const currentDate = new Date();
  const msPerMinute = 60 * 1000;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;
  const msPerMonth = msPerDay * 30;
  const msPerYear = msPerDay * 365;
  
  const elapsed = currentDate - pastDate;
  
  if (elapsed < msPerMinute) {
       return Math.round(elapsed/1000) + ' seconds ago';   
  } else if (elapsed < msPerHour) {
       return Math.round(elapsed/msPerMinute) + ' minutes ago';
  } else if (elapsed < msPerDay ) {
       return Math.round(elapsed/msPerHour) + ' hours ago';   
  } else if (elapsed < msPerMonth) {
      return 'about ' + Math.round(elapsed/msPerDay) + ' days ago';   
  } else if (elapsed < msPerYear) {
      return 'about ' + Math.round(elapsed/msPerMonth) + ' months ago';   
  } else {
      return 'about ' + Math.round(elapsed/msPerYear) + ' years ago';   
  }
}

export function handleAxiosError(error) {
    let errorMessage = '';

    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        errorMessage = `Error: ${error.response.status}. ${error.response.data}`;
    } else if (error.request) {
        // The request was made but no response was received
        errorMessage = `No response received. Check your internet connection.`;
    } else {
        // Something happened in setting up the request that triggered an Error
        errorMessage = `Request error: ${error.message}`;
    }

    return errorMessage;
}
