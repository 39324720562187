import { DocsH1 } from "./helpers/Typography"
import styled from "styled-components"
import { Plus } from 'react-feather';

const EmptyState = styled.div`
  margin: auto;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  top: 25vh;
  position: relative;

  h1 {
    color: ${({ theme }) => theme.colors.white};
    text-align: center;
  }
`

export const Btn = styled.button`
  background-color: ${props => props.theme.colors.secondary};
  color: ${props => props.theme.colors.white};
  font-size: 1.5rem;
  font-family: PPNeue;
  border-radius: 50px;
  padding: 0.75rem 1.25rem;
  font-weight: 500;
  width: fit-content;
  gap: 0.5rem;
  align-items: center;
  display: flex;
  box-shadow: 0px 32px 40px 0px rgba(0, 0, 0, 0.55);

  svg {
    background-color: ${({ theme }) => theme.colors.lightOrange};
    padding: 2px;
    border-radius: 4px;
    color: ${({ theme }) => theme.colors.white};
  }
`

export default function EmptyStandards({ showAddStandard }) {

  return (
   <EmptyState>
    <DocsH1>
      Create New Standard to Begin Tracking Changes
    </DocsH1>
    <Btn onClick={() => showAddStandard()}>
      <Plus/>
      New Standard
    </Btn>
   </EmptyState>
  )
}