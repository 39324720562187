import styled from 'styled-components';
import { Plus } from 'react-feather';

export const Btn = styled.button`
  background-color: ${props => props.theme.colors.secondary};
  color: ${props => props.theme.colors.white};
  font-size: 1.5rem;
  font-family: PPNeue;
  border-radius: 50px;
  padding: 0.75rem 1.25rem;
  font-weight: 500;
  width: fit-content;
  gap: 0.5rem;
  align-items: center;
  display: flex;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0px 32px 40px 0px rgba(0, 0, 0, 0.55);
  z-index: 20;

  svg {
    background-color: ${({ theme }) => theme.colors.lightOrange};
    padding: 2px;
    border-radius: 4px;
    color: ${({ theme }) => theme.colors.white};
  }

  &:hover {
    svg {
      color: ${({ theme }) => theme.colors.white};
    }
  }
`

export default function AddStandardBtn({ showAddStandard }) {

  return (
    <Btn onClick={() => showAddStandard()}>
      <Plus/>
      New Standard
    </Btn>
  );
}