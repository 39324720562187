import { DocsH1, DocsH2, DocsP, DocsSub } from "../helpers/Typography";
import { useState } from "react";
import { FlexRowGap } from "../helpers/FlexContainers";
import styled from 'styled-components';
import DocsOverview from "./DocsOverview";
import GithubOverview from "./GithubOverview";
import GitHookOverview from "./GitHookOverview";
import VSCOverview from "./VSCOverview";

const Container = styled.div`
  padding: 0 1rem;
  max-width: 800px;
  margin: 80px auto 140px auto;
`

const Tab = styled.button`
  background-color: ${({ theme, active }) => active ? theme.colors.grey500 : theme.colors.black};
  outline: none;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.2rem;
  padding: 8px 16px;
`

const Tabs = styled(FlexRowGap)`
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.black};
  padding: 8px;
  width: fit-content;
  margin-bottom: 18px;
  margin-top: 36px;
`

export default function Docs({ hideDocs }) {
  const TAB_OVERVIEW = 0
  const TAB_GITHUB = 1
  const TAB_VSC = 2
  const TAB_GIT_HOOK = 3

  const [tab, setTab] = useState(TAB_OVERVIEW)

  return (
    <Container>
      <DocsH1>Docs</DocsH1>
      <DocsH2>Integrating Bastila into your pipeline</DocsH2>
      <DocsP>
        All requests from your code integration pipeline to Bastila are authenticated using the secret key's generated on the main page.
        This is what lets us know what standards you need to fetch to search for in each repository, which is why you have one secret key
        per repository.
      </DocsP>
      <DocsP>
        Bastila can be integrated in a number of places along your integration pipeline.
      </DocsP>
      <DocsSub>
        1. VISUAL STUDIO CODE - We have a VSC plugin for highlighting the deprecated patterns while you're working. We recommend using either this or git hooks
        so that you can catch yourself using deprecated patterns early in the process.
      </DocsSub>
      <DocsSub>
        2. GIT HOOKS - We have a git hook published on the pypi repository that you can install with <span>pip install bastila-search</span>.
      </DocsSub>
      <DocsSub>
        3. PULL REQUESTS - When pull requests are made this extension will search your code for your predefined values and block the
        pull request from being completed if it adds patterns that you have defined as deprecated. Error messages will
        be displayed in the build to recommend what developers should use instead.
      </DocsSub>
      <DocsSub>
        4. PULL REQUESTS MERGES - When pull requests are merged and completed, this extension will count the instances found of those predefined
        code patterns and POST that data to the Bastila app so that you can see the progress you've made in removing old patterns.
      </DocsSub>
      <DocsP>
        The majority of these steps have one required configuration and two optional configurations.
      </DocsP>
      <DocsSub>
        1. (Required) BASTILA_KEY is the secret key that you generate per repository and is a required configuration.
      </DocsSub>
      <DocsSub>
        2. (Recommended) POST_RESULTS must be set to true in step 4 if you would like to track your progress in removing your deprecated patterns. You
        do not want to set this to true in any other place than when you merge into your main branch.
      </DocsSub>
      <DocsSub>
        3. (Recommended) PREVENT_REGRESSION can be set to true in steps 2, 3, 4 to set whether adding deprecated patterns should block your code contributions. We 
        recommend that you set this to true, otherwise you're not actively preventing deprecated patterns.
      </DocsSub>

      <DocsP>If you run into any issues, send us an email: <a href="mailto:hello@bastila.app">hello@bastila.app</a></DocsP>

      <Tabs gap="12px">
        <Tab active={tab === TAB_OVERVIEW} onClick={() => setTab(TAB_OVERVIEW)}>Overview</Tab>
        <Tab active={tab === TAB_GITHUB} onClick={() => setTab(TAB_GITHUB)}>Github</Tab>
        <Tab active={tab === TAB_VSC} onClick={() => setTab(TAB_VSC)}>VSC</Tab>
        <Tab active={tab === TAB_GIT_HOOK} onClick={() => setTab(TAB_GIT_HOOK)}>Git hook</Tab>
      </Tabs>

      {tab === TAB_OVERVIEW && <DocsOverview/>}
      {tab === TAB_GITHUB && <GithubOverview/>}
      {tab === TAB_VSC && <VSCOverview/>}
      {tab === TAB_GIT_HOOK && <GitHookOverview/>}
    </Container>
  )
}