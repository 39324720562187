import styled from "styled-components";
import { FlexRowSpace } from '../helpers/FlexContainers'

export const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin-top: 1rem;
`

export const ModalHeader = styled(FlexRowSpace)`
  margin-bottom: 1rem;

  svg {
    color: ${({ theme }) => theme.colors.grey300};
    
    &:hover {
      cursor: pointer;
    }
  }
`