import styled from "styled-components";

export const FlexRowSpace = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
`

export const FlexRowGap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${props => props.gap};
`

export const FlexVertStretch = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: ${props => props.gap};
`

export const FlexVertCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${props => props.gap};
`

export const FlexVertSpace = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`