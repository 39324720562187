import { Paragraph, DocsP, DocsH3 } from "../helpers/Typography";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { ClipboardIcon } from '@heroicons/react/24/outline'
import code from '../helpers/action-code.js';
import { useState } from "react";
import { FlexRowSpace } from "../helpers/FlexContainers";
import { TextButton } from "../helpers/Buttons";
import pullRequestCode from '../helpers/pull-request.js';
import mainPushCode from '../helpers/main-push.js';

export default function GithubOverview() {
  const [showIsCopied, setShowIsCopied] = useState(false)

  const copySecret = () => {
    navigator.clipboard.writeText(code)
    setShowIsCopied(true)
    setTimeout(() => {
      setShowIsCopied(false)
    }, 2000)
  }

  return (
    <div>
      <DocsP>
        Start by getting your secret key for the repository you want to setup on the main screen and 
        then <a href="https://docs.github.com/en/actions/security-guides/encrypted-secrets" rel="noopener noreferrer" target="_blank">add
        that key to your github repository settings.</a>
      </DocsP>
      <DocsP>
        After that you will need two workflows setup for steps 1. and 2. above. This is the workflow we have setup for step 1.
      </DocsP>
      <FlexRowSpace style={{ marginBottom: '12px' }}>
        <DocsH3 style={{ marginBottom: '0px' }}>Pull Request Check</DocsH3>
        {showIsCopied ? <Paragraph>Code copied to clipboard</Paragraph> : (
          <TextButton onClick={() => copySecret()}>
            <ClipboardIcon/>
            Copy code to clipboard
          </TextButton>
        )}
      </FlexRowSpace>

      <SyntaxHighlighter language="yaml" style={darcula} customStyle={{
        maxHeight: 500,
        backgroundColor: '#000000',
        marginBottom: '18px'
      }}>
        {pullRequestCode}
      </SyntaxHighlighter>

      <DocsP>And here is the workflow we have setup for step 2.</DocsP>

      <FlexRowSpace style={{ marginBottom: '12px' }}>
        <DocsH3 style={{ marginBottom: '0px' }}>POST Results</DocsH3>
        {showIsCopied ? <Paragraph>Code copied to clipboard</Paragraph> : (
          <TextButton onClick={() => copySecret()}>
            <ClipboardIcon/>
            Copy code to clipboard
          </TextButton>
        )}
      </FlexRowSpace>

      <SyntaxHighlighter language="yaml" style={darcula} customStyle={{
        maxHeight: 500,
        backgroundColor: '#000000',
        marginBottom: '18px'
      }}>
        {mainPushCode}
      </SyntaxHighlighter>
    </div>
  )
}