import { useEffect, useState, useCallback } from 'react';
import { ContentContainer } from './components/helpers/Containers';
import styled from 'styled-components';
import Standard from './components/Standard';
import { useAuth0 } from "@auth0/auth0-react";
import APIClient from './api-client'
import Docs from './components/docs/Docs';
import CoverPage from './components/CoverPage';
import Header from './components/Header';
import StandardModal from './components/modals/StandardModal'
import AddRepoModal from './components/modals/AddRepoModal'
import AddStandardBtn from './components/AddStandardBtn';
import Info from './components/Info';
import ListHeader from './components/ListHeader';
import EmptyStandards from './components/EmptyStandards';
import EmptyRepo from './components/EmptyRepo';
import BuySlotsModal from './components/modals/BuySlotsModal';
import LoadingIcon from 'components/LoadingIcon';
import { handleAxiosError } from './utils'

const AppContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .gradient-bg {
    position: fixed;
    background: linear-gradient(180deg, rgba(36, 36, 36, 0.00) 33.33%, #242424 100%);
    width: 100vw;
    height: 30vh;
    bottom: 0px;
  }
`

const ContentColumn = styled.div`
  text-align: center;
`

const BottomRight = styled.p`
  position: fixed;
  z-index: 2;
  bottom: 2rem;
  right: 2rem;
  font-family: PPNeue;
  font-size: 1.25rem;
`

function App() {
  const [showCreateStandard, setShowCreateStandard] = useState()
  const [standards, setStandards] = useState([])
  const [currentRepo, setCurrentRepo] = useState(null)
  const [repos, setRepos] = useState([])
  const [slotCount, setSlotCount] = useState(0)
  const [loading, setLoading] = useState(true)
  const [showCreateRepo, setShowCreateRepo] = useState(false)
  const [showDocs, setShowDocs] = useState(false)
  const [showOnboarding, setShowOnboarding] = useState(false)
  const [showBuy, setShowBuy] = useState(false)
  const [errorMessage, setErrorMessage] = useState('error')

  const { isAuthenticated, isLoading } = useAuth0()

  const getStandards = useCallback(async () => {
    if (currentRepo === null) return

    try {
      setErrorMessage('')
      const { data } = await APIClient.get(`standard-changes/?repo=${currentRepo.id}`)
      setStandards(data.results)
      setLoading(false)
    } catch (error) {
      setErrorMessage(handleAxiosError(error))
      setLoading(false)
    }
  }, [currentRepo])

  const getRepos = useCallback(async () => {
    try {
      setErrorMessage('')
      const { data } = await APIClient.get('code-repos/')
      setRepos(data.results)

      if (data.results.length > 0) {
        setShowOnboarding(false)
      } else {
        setLoading(false)
        setShowOnboarding(true)
      }
    } catch (error) {
      setErrorMessage(handleAxiosError(error))
      setLoading(false)
    }
  }, [])

  const getSlotCount = useCallback(async () => {
    try {
      setErrorMessage('')
      const { data } = await APIClient.get('slot-count/')
      setSlotCount(data.count)
    } catch (error) {
      setErrorMessage(handleAxiosError(error))
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    if (repos.length > 0 && currentRepo === null) {
      setCurrentRepo(repos[0])
    }
    if (repos.length > 0 && currentRepo !== null) {
      getStandards()
    }
  }, [repos, currentRepo, getStandards])

  useEffect(() => {
    if (isAuthenticated) {
      getSlotCount()
      getRepos()
    }
  }, [getSlotCount, getRepos, isAuthenticated])

  const selectRepo = (option) => {
    const repo = repos.find((r) => r.id === option.id)
    setCurrentRepo(repo)
  }

  const repoCreated = (repo) => {
    getRepos()
    setShowCreateRepo(false)
    setCurrentRepo(repo)
  }

  const addRecord = (newRecord) => {
    setStandards((standards) => [...standards, ...[newRecord]])
    setSlotCount((slotCount) => slotCount -= 1)
    setShowCreateStandard(false)
  }

  const attemptCreateStandard = () => {
    if (slotCount === 0) {
      setShowBuy(true)
    } else {
      setShowCreateStandard(true)
    }
  }

  const removeStandard = (standardId) => {
    setStandards(standards.filter((s) => s.id !== standardId))
    setSlotCount((slotCount) => slotCount += 1)
  }

  const insertUpdatedStandard = (updatedStandard) => {
    setStandards((prev) => prev.map((s) => s.id === updatedStandard.id ? updatedStandard : s))
  }

  const Content = () => {
    if (loading) return <LoadingIcon/>

    return (
      <div>
        <Header
          showingDocs={showDocs}
          setDocs={(value) => setShowDocs(value)}
          repos={repos}
          currentRepo={currentRepo}
          openCreateRepo={() => setShowCreateRepo(true)}
          selectRepo={selectRepo}
          errorMessage={errorMessage}
        />

        {showOnboarding ? (
          <EmptyRepo showAddRepo={() => setShowCreateRepo(true)}/>
        ) : (
          <div>
            {showDocs ? (<Docs hideDocs={() => setShowDocs(false)}/>) : (
              <>
                {standards.length > 0 ? (
                  <ContentContainer>
                    <ContentColumn>
                      <ListHeader currentRepo={currentRepo} />
                      {standards.map((s) => <Standard
                        key={s.id}
                        standard={s}
                        standardDeleted={(id) => removeStandard(id)}
                        standardUpdated={(updated) => insertUpdatedStandard(updated)}
                      />)}
                    </ContentColumn>
                  </ContentContainer>
                ) : (
                  <EmptyStandards showAddStandard={() => setShowCreateStandard(true)}/>
                )}
              </>
            )}
          </div>
        )}

        <StandardModal
          showModal={showCreateStandard}
          closeModal={() => setShowCreateStandard(false)}
          currentRepo={currentRepo}
          addRecord={addRecord}
          standards={standards}
        />
        <AddRepoModal
          showModal={showCreateRepo}
          closeModal={() => setShowCreateRepo(false)}
          addRecord={(repo) => repoCreated(repo)}
        />
        <BuySlotsModal
          showModal={showBuy}
          closeModal={() => setShowBuy(false)}
        />
      </div>
    )
  }

  return (
    <AppContainer cover={!isAuthenticated}>
      {isAuthenticated || isLoading ? (
        <div>
          <Content/>

          {!loading && (
            <>
              {standards.length > 0 && !showDocs && <AddStandardBtn showAddStandard={() => attemptCreateStandard()}/>}
              {!showDocs && <Info slotCount={slotCount}/>}
              <BottomRight>Track • Enforce • Support</BottomRight>
            </>
          )}
        </div>
      ) : (
        <CoverPage/>
      )}

      {(!showDocs && isAuthenticated) && <div className='gradient-bg'/>}
    </AppContainer>
  )
}

export default App;
