import styled from "styled-components";

export const PrimaryBtn = styled.button`
  background-color: ${props => props.theme.colors.secondary};
  color: ${props => props.theme.colors.white};
  font-size: ${props => props.small ? '1.2rem' : '1.8rem'};
  font-family: PPNeue;
  border-radius: 50px;
  padding: ${props => props.small ? '8px 16px' : '12px 24px'};
  font-weight: 500;
  width: fit-content;

  @media (max-width: 680px) {
    padding: 8px 16px;
    font-size: ${props => props.small ? '1rem' : '1.2rem'};
  }
`

export const TextButton = styled.button`
  background-color: transparent;
  color: ${props => props.inverted ? props.theme.colors.secondary : props.theme.colors.white};
  outline: none;
  border: none;
  padding: 0;
  font-size: ${props => props.small ? '1rem' : '1.2rem'};
  display: flex;
  gap: 6px;
  align-items: center;
  font-weight: 500;

  &:hover {
    color: ${props => props.theme.colors.secondary};
  }
`

export const PPTextButton = styled(TextButton)`
  font-family: PPNeue;
`