import { DocsP, DocsSub } from "../helpers/Typography";
import styled from "styled-components";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/hljs';

const Instructions = styled(DocsSub)`
  span {
    display: block;
  }
`

export default function VSCOverview() {
  const vscCode = `
    'settings': { 
      'bastila.BASTILA_KEY': 'Your key'
    }
  `

  return (
    <div>
      <DocsP>
        We have a VSC plugin that will highlight the deprecated patterns you've defined. Here are the installation and setup instructions.
      </DocsP>
      <Instructions>
        <span>1. Install the plugin by using the Visual Studio Code (VSC) command (Ctrl+P/CMD+P) and run 'ext install Bastila.bastila-highlight'</span>
        <span>2. Add your BASTILA_KEY to your VSC settings.json</span>
      </Instructions>
      <SyntaxHighlighter language="json" style={darcula} customStyle={{
        maxHeight: 500,
        backgroundColor: '#000000',
        marginBottom: '18px'
      }}>
        {vscCode}
      </SyntaxHighlighter>
    </div>
  )
}