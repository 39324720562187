import Modal from 'react-modal';
import styled from "styled-components"
import { useState, useEffect } from 'react';
import { X } from 'react-feather';
import { PrimaryBtn } from 'components/helpers/Buttons';
import { Subtext, ModalH3, Paragraph, H3, FormError, H4 } from "../helpers/Typography"
import APIClient from "../../api-client"
import TextInput from "../TextInput"
import { ModalFooter, ModalHeader } from './helpers';
import { useAuth0 } from "@auth0/auth0-react";
import { TextButton } from '../helpers/Buttons';
import { FlexRowGap } from '../helpers/FlexContainers';
import { ClipboardIcon } from '@heroicons/react/24/outline'
import { Key } from 'react-feather';

const RollContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 2rem;
  margin-top: 1rem;
`

export default function RollSecretModal({ showModal, closeModal, currentRepo }) {
  const GENERATE_STEP_START = 0
  const GENERATE_STEP_SHOW_SECRET = 1
  const [generateStep, setGenerateStep] = useState(GENERATE_STEP_START)
  const [secret, setSecret] = useState('')
  const [showIsCopied, setShowIsCopied] = useState(false)
  
  useEffect(() => {
    setGenerateStep(GENERATE_STEP_START)
  }, [showModal])

  const createAPIKey = async () => {
    try {
      const response = await APIClient.post('check-secret/', {
        repo_id: currentRepo.id
      })
      setSecret(response.data.key)
      setGenerateStep(GENERATE_STEP_SHOW_SECRET)
    } catch {
    }
  }

  const copySecret = () => {
    navigator.clipboard.writeText(secret)
    setShowIsCopied(true)
    setTimeout(() => {
      setShowIsCopied(false)
    }, 2000)
  }

  return (
    <Modal
      isOpen={showModal}
      onRequestClose={closeModal}
      style={{
        overlay: {
          backgroundColor: 'rgba(24, 24, 24, 0.8)'
        },
        content: {
          backgroundColor: '#000000',
          boxShadow: '0px 40px 40px 0px #00000099',
          width: '100%',
          maxWidth: '600px',
          margin: 'auto',
          height: 'fit-content',
          border: 'none',
          borderRadius: '4px',
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }
      }}
    >
      <ModalHeader>
        <ModalH3>Roll Secret Key</ModalH3>
        <X onClick={() => closeModal()} />
      </ModalHeader>

      {generateStep === GENERATE_STEP_SHOW_SECRET ? (
        <Paragraph>Copy and save this key somewhere safe. <strong>You cannot view it later</strong> and will have to reroll it if you lose it.</Paragraph>
      ) : (
        <>
          <Paragraph>This secret key is used to authenticate all of your integrations with Bastila for repository <strong>{currentRepo.commonName}</strong>.</Paragraph>
          <Paragraph>If you roll your secret key it will <strong>unauthorize all integrations</strong> you have setup with this repository.</Paragraph>
        </>
      )}

      <RollContainer>
        {generateStep === GENERATE_STEP_START && (
          <PrimaryBtn small={true} onClick={() => createAPIKey()}>
            Roll Your Secret Key
          </PrimaryBtn>
        )}
        {generateStep === GENERATE_STEP_SHOW_SECRET && (
          <>
            {showIsCopied ? <Paragraph>Secret copied to clipboard</Paragraph> : (
              <TextButton onClick={() => copySecret()}>
                <ClipboardIcon/>
                { secret }
              </TextButton>
            )}
          </>
        )}
      </RollContainer>

      <ModalFooter>
        <div/>
        <PrimaryBtn small={true} onClick={() => closeModal()}>Close</PrimaryBtn>
      </ModalFooter>
    </Modal>
  )
}