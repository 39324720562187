import { FlexVertStretch } from "./helpers/FlexContainers";
import { PrimaryBtn } from "./helpers/Buttons";
import { Subtext } from "./helpers/Typography";
import { useAuth0 } from "@auth0/auth0-react";

export default function Auth() {
  const { loginWithRedirect } = useAuth0();

  return (
    <FlexVertStretch gap="8px">
      <PrimaryBtn onClick={() => loginWithRedirect()}>
        <span>Try it out</span>
      </PrimaryBtn>
      <Subtext>Beta</Subtext>
    </FlexVertStretch>
  );
}