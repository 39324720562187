import Modal from 'react-modal';
import styled from "styled-components"
import { useState } from 'react';
import { X } from 'react-feather';
import { PrimaryBtn } from 'components/helpers/Buttons';
import { Subtext, ModalH3, Paragraph, H3, FormError, H4 } from "../helpers/Typography"
import APIClient from "../../api-client"
import TextInput from "../TextInput"
import { ModalFooter, ModalHeader } from './helpers';
import { useAuth0 } from "@auth0/auth0-react";

const Slots = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: stretch;
`

const Option = styled.button`
  padding: 1rem;
  border-radius: 4px;
  border: ${({ theme, active }) => active ? `1px solid ${theme.colors.secondary}` : `1px solid ${theme.colors.grey300}`};
  display: flex;
  flex-direction: column;
  gap: 3rem;
  background-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
  flex-grow: 1;
  align-items: flex-start;
  text-align: left;
`

export default function BuySlotsModal({ showModal, closeModal }) {
  const [code, setCode] = useState('')
  const [error, setError] = useState('')
  const { user } = useAuth0()

  const CODE10 = 'aEUdUE2YZ2QaeoU8wy'
  const CODE30 = 'dR6g2Mbvv2Qaa8E289'
  const CODE50 = '4gwcQAfLLbmGcgM6oo'

  const openLink = () => {
    setError('')
    if (code === '') {
      setError('Select a slot purchase to continue')
      return
    }

    window.open(`https://buy.stripe.com/${code}?prefilled_email=${user.email}`, '_blank');
  }

  return (
    <Modal
      isOpen={showModal}
      onRequestClose={closeModal}
      style={{
        overlay: {
          backgroundColor: 'rgba(24, 24, 24, 0.8)'
        },
        content: {
          backgroundColor: '#000000',
          boxShadow: '0px 40px 40px 0px #00000099',
          width: '100%',
          maxWidth: '600px',
          margin: 'auto',
          height: 'fit-content',
          border: 'none',
          borderRadius: '4px',
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          border: error ? '1px solid #FF3232' : ''
        }
      }}
    >
      <ModalHeader>
        <ModalH3>You're out of slots</ModalH3>
        <X onClick={() => closeModal()} />
      </ModalHeader>

      <Paragraph>To add a new standard, you need to select and purchase more slots</Paragraph>

      <Slots>
        <Option onClick={() => setCode(CODE10)} active={code === CODE10}>
          <div>
            <H4>10 Slots</H4>
            <Subtext>$4 per slot</Subtext>
          </div>
          <Paragraph>$40</Paragraph>
        </Option>
        <Option onClick={() => setCode(CODE30)} active={code === CODE30}>
          <div>
            <H4>30 Slots</H4>
            <Subtext>$3.30 per slot</Subtext>
          </div>
          <Paragraph>$100</Paragraph>
        </Option>
        <Option onClick={() => setCode(CODE50)} active={code === CODE50}>
          <div>
            <H4>50 Slots</H4>
            <Subtext>$3 per slot</Subtext>
          </div>
          <Paragraph>$150</Paragraph>
        </Option>
      </Slots>

      <ModalFooter>
        <FormError>{ error }</FormError>
        <PrimaryBtn small={true} onClick={() => openLink()}>Continue</PrimaryBtn>
      </ModalFooter>
    </Modal>
  )
}