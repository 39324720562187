import { FlexRowGap, FlexRowSpace, FlexVertStretch } from "./helpers/FlexContainers";
import { Instructions, Code, Subtext } from "./helpers/Typography";
import { TextButton } from "./helpers/Buttons";
import styled from "styled-components";
import APIClient from "api-client"
import { getRelativeDateString } from '../utils'
import { Trash, ShieldOff, Shield } from "react-feather";

const Progress = styled.div`
  flex: ${({ flex }) => flex};
  background-color: ${(props) => props.theme.colors.secondary};
  text-align: right;
  height: 24px;

  p {
    margin-right: 6px;
    line-height: 24px;
    color: ${(props) => props.theme.colors.white};
  }
`

const Remaining = styled.div`
  flex: ${({ flex }) => flex};
  background-color: ${(props) => props.theme.colors.darkOrange};
  text-align: right;
  height: 24px;

  p {
    margin-right: 6px;
    line-height: 24px;
    color: ${(props) => props.theme.colors.white};
  }
`

const ProgressCard = styled.div`
  border-radius: 4px 4px 0 0;
  padding: 1rem;
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};
  text-align: left;
  border-color: ${(props) => props.theme.colors.grey400};
  border-style: solid;
  border-width: 1px 1px 0 1px;
`

const ProgressBar = styled(FlexRowGap)`
  border-color: ${(props) => props.theme.colors.grey400};
  border-style: solid;
  border-width: 0 1px 0px 1px;
`

const Footer = styled(FlexRowGap)`
  border-radius: 0 0 4px 4px;
  border-color: ${(props) => props.theme.colors.grey400};
  border-style: solid;
  border-width: 0 1px 1px 1px;
  padding: 0.75rem 1rem;
  min-height: 26px;
  background-color: ${(props) => props.theme.colors.primary};
`


export default function CurrentStandard({ standard, standardDeleted, standardUpdated }) {
  const numberOfFixes = (standard.firstCount - standard.previousCount) || 0
  const noIssuesFound = standard.firstCount === 0
  const firstSearchDone = standard.previousCount !== undefined && standard.previousCount !== null
  const taskDone = standard.firstCount > 0 && standard.previousCount === 0
  const progressText = taskDone ? 'Task Done' : `${numberOfFixes} / ${standard.firstCount}`
  const progressMade = standard.firstCount > 0 && standard.previousCount < standard.firstCount
  const showDelete = !progressMade

  const deleteChange = async () => {
    try {
      await APIClient.delete(`standard-changes/${standard.id}/`)
      standardDeleted(standard.id)
    } catch {
      
    }
  }

  const patchDisabled = async () => {
    try {
      const { data } = await APIClient.patch(`standard-changes/${standard.id}/`, {
        is_disabled: !standard.isDisabled
      })
      standardUpdated(data)
    } catch {
      
    }
  }

  const progressMessage = () => {
    if (noIssuesFound) {
      return 'Search successful but no data found'
    }
    if (firstSearchDone) {
      return ''
    }
    return 'No data yet'
  }
  
  const FivePercentProgress = numberOfFixes && standard.previousCount && ((numberOfFixes / (standard.previousCount || 1)) > 0.05)
  return (
    <div style={{ marginBottom: '18px' }}>
      <ProgressCard>
        <FlexVertStretch gap="8px">
          <FlexRowSpace>
            <FlexRowGap gap="8px">
              <Code>{ standard.snippet }</Code>
            </FlexRowGap>
            <FlexRowGap gap="8px">
              <Subtext>{ progressMessage() }</Subtext>
              {showDelete ? (
                <TextButton inverted={true} small={true} onClick={() => deleteChange()}><Trash/></TextButton>
              ) : (
                <Subtext>{ getRelativeDateString(new Date(standard.lastUpdate)) }</Subtext>
              )}
              <TextButton inverted={true} small={true} onClick={() => patchDisabled()}>
                { standard.isDisabled ? <ShieldOff/> : <Shield/> }
              </TextButton>
            </FlexRowGap>
          </FlexRowSpace>
          <Instructions>{ standard.fixRecommendation }</Instructions>
        </FlexVertStretch>
      </ProgressCard>
      <ProgressBar>
        {numberOfFixes !== 0 && <Progress flex={numberOfFixes}>
          <Subtext>{FivePercentProgress ? progressText : ''}</Subtext>
        </Progress>}
        {!taskDone && <Remaining flex={standard.previousCount || 1}>
          <Subtext>{FivePercentProgress || !standard.firstCount ? '' : progressText}</Subtext>
        </Remaining>}
      </ProgressBar>
      <Footer gap="1rem">
        {standard.includePaths && standard.includePaths.length > 0 && <Subtext>Include files: { standard.includePaths }</Subtext>}
        {standard.excludePaths && standard.excludePaths.length > 0 && <Subtext>Exclude files: { standard.excludePaths }</Subtext>}
      </Footer>
    </div>

  )
}